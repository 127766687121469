<template>
  <div>
    <c-card class="cardClassDetailInfo" :noHeader="true">
      <template slot="card-detail">
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
          <c-label-text title="LBLPLANT" :value="fireFighting.plantName"></c-label-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-label-text title="소방시설 및 장비명" :value="fireFighting.fireFightingName"></c-label-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
          <c-label-text title="설비종류" :value="fireFighting.sopFireFightingTypeName"></c-label-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
          <c-label-text title="설치번호" :value="fireFighting.manageNo"></c-label-text>
        </div>
      </template>
    </c-card>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <c-table
          ref="table"
          title="소방 화재수신기 작동 이력 목록"
          :columns="gridHistory.columns"
          :data="fireFighting.histroy"
          :gridHeight="gridHeight"
          @linkClick="linkClick"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <c-table
          ref="table"
          title="소방 정기점검 이력 목록"
          :columns="gridCheck.columns"
          :data="fireFighting.checks"
          :gridHeight="gridHeight"
          @linkClick="linkClick"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <c-table
          ref="table"
          title="소방 이슈점검 이력 목록"
          :columns="gridIssueCheck.columns"
          :data="fireFighting.issueChecks"
          :gridHeight="gridHeight"
          @linkClick="linkClick"
        >
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
export default {
  name: 'fireFightingHistory',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingId: '',
      }),
    },
    fireFighting: {
      type: Object,
      default: () => ({
        sopFireFightingId: '',  // 소방설비 일련번호
        plantCd: null,  // 사업장
        plantName: null,  // 사업장
        sopFireFightingTypeCd: null,  // 유형
        sopFireFightingTypeName: null,  // 유형
        manageNo: '',  // 설치번호
        fireFightingName: '',  // 소방설비명
        managerId: '',  // 담당자
        deptCd: '',  // 관리부서
        amount: '',  // 수량
        chmDangerMstId: '',  // 관련 위험물 저장소 일련번호
        remarks: '', // 비고(특이사항)
        installDate: '',  // 제조년월
        disposeFlag: 'N',  // 폐기여부
        disposeDate: '',  // 폐기일
        locationName: '',
        deleteFlag: '',  // 삭제여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        histroy: [],
        checks: [],
        issueChecks: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      gridHistory: {
        columns: [
          // {
          //   name: 'plantName',
          //   field: 'plantName',
          //   label: 'LBLPLANT',
          //   align: 'center',
          //   sortable: true,
          //   style: 'width: 100px',
          // },
          {
            name: 'reportDate',
            field: 'reportDate',
            label: '보고일',
            align: 'center',
            sortable: true,
            style: 'width: 150px',
          },
          {
            name: 'fireFightingGroupName',
            field: 'fireFightingGroupName',
            label: '소방설비',
            align: 'left',
            sortable: true,
            style: 'width: 200px',
            type: 'link'
          },
          {
            name: 'repeater',
            field: 'repeater',
            label: '중계기 번호',
            align: 'center',
            sortable: true,
            style: 'width: 120px',
          },
          {
            name: 'location',
            field: 'location',
            label: '장소',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      gridCheck: {
        columns: [
          // {
          //   name: 'plantName',
          //   field: 'plantName',
          //   label: 'LBLPLANT',
          //   align: 'center',
          //   sortable: true,
          //   style: 'width: 100px',
          // },
          {
            name: 'checkName',
            field: 'checkName',
            label: '점검명',
            align: 'left',
            sortable: true,
            style: 'width: 200px',
            type: 'link'
          },
          {
            name: 'sopFireFightingCheckStepName',
            field: 'sopFireFightingCheckStepName',
            label: 'LBLPROGRESS',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: "checkDate",
            field: "checkDate",
            label: "점검일",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "checkerName",
            field: "checkerName",
            label: "점검자",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "reportDate",
            field: "reportDate",
            label: "보고일",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "receivedReportName",
            field: "receivedReportName",
            label: "확인자",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      gridIssueCheck: {
        columns: [
          // {
          //   name: 'plantName',
          //   field: 'plantName',
          //   label: 'LBLPLANT',
          //   align: 'center',
          //   sortable: true,
          //   style: 'width: 100px',
          // },
          {
            name: 'issueCheckName',
            field: 'issueCheckName',
            label: '점검명',
            align: 'left',
            sortable: true,
            style: 'width: 200px',
            type: 'link',
          },
          {
            name: 'yearmonth',
            field: 'yearmonth',
            label: '점검년월',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '점검부서',
            align: 'center',
            sortable: true,
            style: 'width: 120px',
          },
          {
            name: 'sopFireFightingIssueStepName',
            field: 'sopFireFightingIssueStepName',
            label: '진행상태',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
        ],
        data: [],
      },
      editable: true,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      let height = this.contentHeight - 180;
      if (height < 650) {
        height = 650
      }
      return String(height) + 'px'
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col) {
      if (col.name === 'fireFightingGroupName') {
        this.popupOptions.title = '소방 화재수신기 작동 이력 보고';
        this.popupOptions.param = {
          sopFireFightingReceivingSetHistoryId: row.sopFireFightingReceivingSetHistoryId
        };
        this.popupOptions.target = () => import(`${'@/pages/fft/receiving/fireFightingReceivingSetHistoryDetail.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.isFull = true;
        this.popupOptions.closeCallback = this.closePopup;
      } else if (col.name === 'checkName') {
        this.popupOptions.title = '소방설비 점검 상세';
        this.popupOptions.param = {
          sopFireFightingCheckId: row.sopFireFightingCheckId
        };
        this.popupOptions.target = () => import(`${'@/pages/fft/fireFightingCheckDetail.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.isFull = true;
        this.popupOptions.closeCallback = this.closePopup;
      } else {
        this.popupOptions.title = '소방 이슈점검 상세';
        this.popupOptions.param = {
          sopFireFightingIssueCheckId: row.sopFireFightingIssueCheckId
        };
        this.popupOptions.target = () => import(`${'@/pages/fft/ise/fireFightingIssueCheckDetail.vue'}`);
        this.popupOptions.visible = true;
        this.popupOptions.isFull = true;
        this.popupOptions.closeCallback = this.closePopup;
      }
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>